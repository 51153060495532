<template>
  <KModal
    :show.sync="showModal"
    :no-close-on-backdrop="true"
    :centered="true"
    title="Confirm Dialog"
    :color="color"
  >
    <div>
    {{message}}
    </div>
    <template #header>
      <h6 class="modal-title text-center">{{title}}</h6>
    </template>
    <template #footer>
      <CButton @click="confirm()" color="info">{{button}}</CButton>
      <CButton @click="cancel()" color="light">취소</CButton>
    </template>
  </KModal>
</template>

<script>
export default {
  name: 'Confirm',
  props: {
    color: {
      type: String,
      default: 'info'
    }
  },
  data() {
    return {
      showModal: false,
      title: '확인',
      which: 'confirm',
      message: 'this is placeholder of message',
      button: '확인'
    }
  },
  methods: {
    confirm() {
      let msg = {
        name: this.$options.name,
        which: this.which,
        data: 'confirm'
      }
      this.$emit('hide', msg);
      this.showModal = false;
    },
    cancel() {
      let msg = {
        name: this.$options.name,
        which: this.which,
        data: 'cancel'
      }
      this.$emit('hide', msg);
      this.showModal = false;
    },
    show(message, title, button, which) {
      this.message = message;
      if (!_.isUndefined(title)) this.title = title
      if (!_.isUndefined(button)) this.button = button
      if (!_.isUndefined(which)) this.which = which
      this.showModal = true;
    }
  }
}
</script>