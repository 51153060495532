export function setAccessToken(state, token) {
  // eslint-disable-next-line camelcase
  state.access_token = token;
}

export function setAccount(state, account) {
  state.account = account;
}

export function setUserInfo(state, info) {
  state.user_info = info
}

export function setIsAdmin(state, is_admin) {
  state.is_admin = is_admin
}