/* ========================= css conflict ============================= */
import './plugins/table.js'
import 'babel-polyfill';

import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import {sync} from 'vuex-router-sync';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas as fa } from '@fortawesome/free-solid-svg-icons'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fa)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)

import VueLogger from "vuejs-logger";
const env = process.env.NODE_ENV;
// const logLevel = env === 'development' ? 'debug' : env === 'info' ? 'debug' : env === 'production' ? 'error' : 'info'
const logLevel = 'info';
const options = {
    isEnabled: true,
    logLevel: logLevel,
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: '|',
    showConsoleColors: true
}
Vue.use(VueLogger, options);

if (!String.prototype.format) {
  String.prototype.format = function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return this.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] !== 'undefined' ? args[number] : match;
    });
  };
}

Vue.config.performance = true
Vue.use(CoreuiVue)

Vue.prototype.$log = console.log.bind(console)

/* 
import { CModal } from '@coreui/vue'
const KModal = {
  name: 'KModal',
  extends: CModal,
  methods: {
    hide (e, accept = false) {
      // this.$emit('update:show', false, e, accept)
      this.$emit('close')
    }
  }
}
Vue.component('KModal', KModal)
*/
/* added d-block for slot #header */
import KModal from '@/views/ext/KModal'
Vue.component('KModal', KModal)

import FileUpload from 'vue-upload-component'
const SipFileUpload = {
  name: 'SipFileUpload',
  extends: FileUpload,
  props: {
    ...FileUpload.props,
    indicator: {
      type: String,
      default: 'file'
    }
  },
  methods: {
    uploadHtml5(file) {
      let form = new window.FormData()
      let value
      for (let key in file.data) {
        value = file.data[key]
        if (value && typeof value === 'object' && typeof value.toString !== 'function') {
          if (value instanceof File) {
            form.append(key, value, value.name)
          } else {
            form.append(key, JSON.stringify(value))
          }
        } else if (value !== null && value !== undefined) {
          form.append(key, value)
        }
      }

      // form.append(this.name, file.file, file.file.filename || file.name)
      form.append(this.indicator, file.file, file.file.filename || file.name)
      let xhr = new XMLHttpRequest()
      xhr.open('POST', file.postAction)
      return this.uploadXhr(xhr, file, form)
    },
  }
}
Vue.component('SipFileUpload', SipFileUpload)

import Confirm from '@/views/popups/Confirm'
Vue.component('Confirm', Confirm)
import Notify from '@/views/popups/Notify'
Vue.component('Notify', Notify)

import EventBus from '@/services/EventBus'
import ECharts from 'vue-echarts' // refers to components/ECharts.vue in webpack
import 'echarts/lib/chart/line'
import 'echarts/lib/component/polar'
Vue.component('v-chart', ECharts)

store.dispatch('auth/syncRouter', {
  router: router,
  EventBus: EventBus
});
sync(store, router);

import 'lodash'
import vueMoment from 'vue-moment'
Vue.use(vueMoment.install)

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
Vue.component('date-picker', DatePicker)

import VueUploadComponent from 'vue-upload-component'
Vue.component('file-upload', VueUploadComponent)

import VueHtml2Canvas from 'vue-html2canvas';
Vue.use(VueHtml2Canvas);

function _isMobile() {
  var isMobile = (/iphone|ipod|android|ie|blackberry|fennec/).test(navigator.userAgent.toLowerCase());
  return isMobile;
}

import utils from '@/services/utils';
utils.loadSensorTypes(function(sensor_type) {
  Vue.prototype.sensor_type = sensor_type
});

Vue.prototype.$is_mobile = _isMobile();

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
 })

