const DEVICE_TABLE_OPTIOINS = {
    rowStyle: function(row, index) {
        return {
            classes: 'none'
    };
    },
    formatNoMatches: function() {
        return '-';
    },
    cache: false,
    height: 530,
    striped: false,
    pagination: false,
    pageSize: 10,
    pageList: [5, 10, 25, 50, 100, 200],
    search: false,
    showColumns: false,
    showRefresh: false,
    minimumCountColumns: 2,
    clickToSelect: false,
    singleSelect: false,
    showToggle: false,
    maintainSelected: false,
    sortName: 'name',
    sortOrder: 'asc',
    uniqueId: 'guid',
}

const DEVICE_TABLE_COLUMS = [
    {
        field: 'site',
        title: '사이트명',
        align: 'center',
        valign: 'top',
        sortable: true,
        visible: false
    }, {
        field: 'center_device_guid',
        title: '중심디바이스',
        align: 'center',
        valign: 'top',
        visible: false
    }, {
        field: 'latitude',
        title: '위도',
        align: 'center',
        valign: 'top',
        visible: false
    }, {
        field: 'longitude',
        title: '경도',
        align: 'center',
        valign: 'top',
        visible: false
    }, {
        field: 'name',
        title: '디바이스명',
        align: 'left',
        valign: 'top',
        sortable: true,
    }, {
        field: 'watering',
        title: '자동관수',
        align: 'center',
        valign: 'top',
        sortable: false,
        visible: false,
    }, {
        field: 'nutrient',
        title: '양액통',
        align: 'center',
        valign: 'top',
        sortable: false,
        visible: false,
    }, {
        field: 'address',
        title: '주소',
        align: 'left',
        valign: 'top',
        sortable: true,
        // visible: isMobile ? false : true
        visible: false
    }, {
        field: 'sensor',
        title: '<a href="#/summarize">최근 측정값</a>',
        // title: '최근 측정값',
        align: 'center',
        valign: 'top',
        width: '400px',
        sortable: false,
    }, {
        field: 'prev_time',
        title: '수신 예상 시간',
        align: 'left',
        valign: 'top',
        sortable: true,
    }, {
        field: 'sensor_time',
        title: '최근 측정시간',
        align: 'left',
        valign: 'top',
        sortable: true,
    }, {
        field: 'guid',
        title: '링크',
        align: 'center',
        valign: 'top',
        visible: false
    }
]


const SRDS_DEVICE_TABLE_COLUMS = [
    {
        field: 'site',
        title: '사이트명',
        align: 'center',
        valign: 'top',
        sortable: true,
        visible: false
    }, {
        field: 'name',
        title: '디바이스명',
        align: 'left',
        valign: 'top',
        sortable: true,
    }, {
        field: 'address',
        title: '주소',
        align: 'left',
        valign: 'top',
        sortable: true,
        // visible: isMobile ? false : true
        visible: false
    }, {
        field: 'level',
        title: '위험등급',
        align: 'center',
        valign: 'top',
        sortable: true,
    },{
        field: 'battery',
        title: '배터리',
        align: 'center',
        valign: 'top',
        visible: true
    },{
        field: 'sensor',
        title: '<a href="#/srds_summarize" style="color:black">최근 사진</a>',
        // title: '최근 측정값',
        align: 'center',
        valign: 'top',
        width: '220px',
        sortable: false,
    },{
        field: 'sensor_time',
        title: '최근 측정시간',
        align: 'left',
        valign: 'top',
        sortable: true,
    },  {
        field: 'guid',
        title: '링크',
        align: 'center',
        valign: 'top',
        visible: false
    },
]

const FUMAX_DEVICE_TABLE_COLUMNS = [
    {
        field: 'state',
        checkbox: true
    },
    {
        field: 'site',
        title: '사이트명',
        align: 'center',
        valign: 'top',
        sortable: true,
        visible: false
    }, {
        field: 'center_device_guid',
        title: '중심디바이스',
        align: 'center',
        valign: 'top',
        visible: false
    }, {
        field: 'latitude',
        title: '위도',
        align: 'center',
        valign: 'top',
        visible: false
    }, {
        field: 'longitude',
        title: '경도',
        align: 'center',
        valign: 'top',
        visible: false
    }, {
        field: 'name',
        title: '디바이스명',
        align: 'left',
        valign: 'top',
        sortable: true,
    }, 
    {
        field: 'deicing',
        title: '제설제 잔량',
        align: 'center',
        valign: 'top',
        sortable: false,
        visible: false 
    },
    {
        field: 'address',
        title: '주소',
        align: 'left',
        valign: 'top',
        sortable: true,
        // visible: isMobile ? false : true
        visible: false
    },{
        field: 'battery',
        title: '전압',
        align: 'center',
        valign: 'top',
        visible: true
    },{
        field: 'waterlevel',
        title: '수위',
        align: 'center',
        valign: 'top',
        visible: true
    },{
        field: 'sensor',
        title: "온도/습도",
        //title: '<a href="#/summarize" style="color:black">최근 사진</a>',
        // title: '최근 측정값',
        align: 'center',
        valign: 'top',
        width: '220px',
        sortable: false,
    },{
        field: 'sensor_time',
        title: '최근 측정시간',
        align: 'left',
        valign: 'top',
        sortable: true,
        visible: false
    },  {
        field: 'guid',
        title: '링크',
        align: 'center',
        valign: 'top',
        visible: false
    },    {
        field: 'connecting',
        title: '수신상태',
        align: 'center',
        valign: 'top',
        sortable: false,
        visible: true 
    },
    {
        field: 'activation',
        title: '분사제어',
        align: 'center',
        valign: 'top',
        sortable: false,
        visible: true 
    }, 
]

const SNOW_DEVICE_TABLE_COLUMS = [
    {
        field: 'site',
        title: '사이트명',
        align: 'center',
        valign: 'top',
        sortable: true,
        visible: false
    }, {
        field: 'name',
        title: '디바이스명',
        align: 'left',
        valign: 'top',
        sortable: true,
    },{
        field: 'battery',
        title: '배터리',
        align: 'center',
        valign: 'top',
        visible: true
    },{
        field: 'sensor',
        title: "온도/습도",
        align: 'center',
        valign: 'top',
        width: '220px',
        sortable: false,
    },{
        field: 'images',
        title: '최근 사진',
        align: 'center',
        valign: 'top',
        width: '220px',
        visible: true
    },{
        field: 'sensor_time',
        title: '최근 측정시간',
        align: 'left',
        valign: 'top',
        sortable: true,
    }
]

const DOOR_DEVICE_TABLE_COLUMS = [
    {
        field: 'site',
        title: '사이트명',
        align: 'center',
        valign: 'top',
        sortable: true,
        visible: false
    }, {
        field: 'name',
        title: '디바이스명',
        align: 'left',
        valign: 'top',
        sortable: true,
    }, {
        field: 'level',
        title: '도어센서',
        align: 'center',
        valign: 'top',
        sortable: true,
        //visible: false
    },{
        field: 'battery',
        title: '배터리',
        align: 'center',
        valign: 'top',
        visible: true
    },{
        field: 'sensor',
        title: '<a href="#/summarize" style="color:black">최근 사진</a>',
        // title: '최근 측정값',
        align: 'center',
        valign: 'top',
        width: '220px',
        sortable: false,
    },{
        field: 'sensor_time',
        title: '최근 측정시간',
        align: 'left',
        valign: 'top',
        sortable: true,
    }
]

const DEVICE_DETAIL_OPTIOINS = {
    rowStyle: function(row, index) {
        return {
            classes: 'none'
        };
    },
    formatNoMatches: function() {
    return '-';
    },
    height: 450,
    showHeader: false,
    striped: true,
}

const DEVICE_DETAIL_COLUMS = [
    {
        field: 'name',
        width: '30%',
        align: 'left',
        valign: 'middle',
        cellStyle: function(value) {
          return {
            classes: 'info'
          };
        },
    }, {
        field: 'value',
        width: '70%',
        align: 'left',
        valign: 'middle',
    }
]

const CRONSTRING_TYPES = [
    {
        id: 0, 
        name: "10분",
        value: "*/10 * * * *"
    }, 
    {
        id: 1, 
        name: "20분",
        value: "*/20 * * * *"
    }, 
    {
        id: 2, 
        name: "30분",
        value: "*/30 * * * *"
    }, {
        id: 3, 
        name: "1시간",
        value: "1 */1 * * *"
    }, {
        id: 4, 
        name: "3시간",
        value: "0 */3 * * *"
    },
    {
        id: 5, 
        name: "8시간(8,16,24)",
        value: "0 */8 * * *"
    },
    {
        id: 6, 
        name: "8시간(6,14,22)",
        value: "0 6,14,22 * * *"
    }    
    // {
    //     id: 0, 
    //     name: "1분",
    //     value: "*/1 * * * *"
    // }, 
    // {
    //     id: 1, 
    //     name: "5분",
    //     value: "*/5 * * * *"
    // }, 
    // {
    //     id: 2, 
    //     name: "10분",
    //     value: "*/10 * * * *"
    // }, 
    // {
    //     id: 3, 
    //     name: "20분",
    //     value: "*/20 * * * *"
    // }, 
    // {
    //     id: 4, 
    //     name: "30분",
    //     value: "*/30 * * * *"
    // }, {
    //     id: 5, 
    //     name: "1시간",
    //     value: "1 */1 * * *"
    // }, {
    //     id: 6, 
    //     name: "3시간",
    //     value: "0 */3 * * *"
    // },
    // {
    //     id: 7, 
    //     name: "8시간(8,16,24)",
    //     value: "0 */8 * * *"
    // },
    // {
    //     id: 8, 
    //     name: "8시간(6,14,22)",
    //     value: "0 6,14,22 * * *"
    // }
]

const WATERING_TYPES = [
    {
        id: 0, 
        name: "자동관수 정지",
        value: ""
    }, 
    {
        id: 1, 
        name: "1분",
        value: "*/1 1 1"
    }, 
    {
        id: 2, 
        name: "2분",
        value: "*/1 2 1"
    }, 
    {
        id: 3, 
        name: "5분",
        value: "*/1 5 1"
    }, {
        id: 4, 
        name: "10분",
        value: "*/1 10 1"
    }, {
        id: 5, 
        name: "30분",
        value: "*/1 30 1"
    }, {
        id: 6, 
        name: "1시간",
        value: "*/1 60 1"
    },
    {
        id: 7, 
        name: "2시간",
        value: "*/1 120 1"
    },
    {
        id: 8, 
        name: "2시간 5분",
        value: "*/1 125 1"
    }    
]

const LEAF_TYPES = {
    'Apple___healthy': '정상', 
    'Apple___Apple_scab': '사과 부패병', 
    'Apple___Black_rot': '흑균병,부패병', 
    'Apple___Cedar_apple_rust': '적성병,붉은별무늬병' ,
    'healthy': '정상', 
    'multiple_diseases': '사과 복합병',
    'rust': '적성병,붉은별무늬병',
    'scab': '사과 부패병'
}

export {
    DEVICE_TABLE_OPTIOINS,
    DEVICE_TABLE_COLUMS,
    SRDS_DEVICE_TABLE_COLUMS,
    FUMAX_DEVICE_TABLE_COLUMNS,
    SNOW_DEVICE_TABLE_COLUMS,
    DEVICE_DETAIL_OPTIOINS,
    DEVICE_DETAIL_COLUMS,
    DOOR_DEVICE_TABLE_COLUMS,
    CRONSTRING_TYPES,
    WATERING_TYPES,
    LEAF_TYPES
}
