import axios from 'axios';
import Stomp from 'stomp-websocket'
import router from '@/router';

import {proto, restApiRoot, restApiHost, manApiHost, port, dns, stomp as info} from '@/config.json';
// import {DateString} from 'loopback';

// DateString.parse = function(dateString) {
//   const date = dateString.when || dateString;
//   return new DateString(date);
// };

// export {DateString};

const Storage = window.localStorage;

/**
 * Add a token in the local storage
 * */
function exportTokenToLocalStorage(token) {
  if (Storage) {
    Storage.setItem('loopback-token', JSON.stringify(token));
  }
}

/**
 * Remove token from local storage
 */
function removeTokenFromLocalStorage() {
  if (Storage) {
    Storage.removeItem('loopback-token');
  }
}

function addTokenFromLocalStorage(http) {
  const token = Storage && Storage.getItem('loopback-token');
  if (token) http.setToken(JSON.parse(token), false);
}

const http = axios.create({
  baseURL: dns ? `${proto}://${restApiHost}${restApiRoot}` : `${proto}://${restApiHost}:${port}${restApiRoot}`,
});

// Current setLoading function
let setLoading = () => {
  console.warn('[loopback service] setLoadingFunction is not defined');
};

http.setLoadingFunction = (fn) => {
  setLoading = fn;
};

http.setToken = (token, save = true) => {
  http.token = token;
  http.defaults.headers.common.Authorization = token.id;
  if (save) exportTokenToLocalStorage(token);
};

http.getToken = () => {
  return http.token;
};

http.removeToken = () => {
  delete http.defaults.headers.common.Authorization;
  removeTokenFromLocalStorage();
};

http.portainer = () => {
  return `${proto}://${manApiHost}`;
};

http.explorer = () => {
  return dns ? `${proto}://${restApiHost}/explorer/` : `${proto}://${restApiHost}:${port}/explorer/`;
};

http.mongoexp = () => {
  return dns ? `${proto}://${restApiHost}/mongo-exp/` : `${proto}://${restApiHost}:18081/mongo-exp/`;
};

http.rabbitmq = () => {
  return dns ? `${proto}://${restApiHost}/rabbitmq/` : `${proto}://${restApiHost}:15672/rabbitmq/`;
};

/*
 * @param filter: 
 * {
 *   where: {
 *     something: 'value'
 *   }
 * }
 * @param where: 
 * {
 *   something: 'value'
 * }
*/
// http.find = (endpoint, filter) => http.get(endpoint, {params: {filter}});
http.find = (model, filter) => {
  let path = model
  if (filter) path += '?filter=' + JSON.stringify(filter)
  return http.get(path)
}

http.findById = (model, guid) => {
  let path = '{0}/{1}'.format(model, guid);
  return http.get(path)
}

http.count = (model, where) => {
  var path = model + '/count'
  if (typeof where !== 'undefined')
    path += '?where={0}'.format(JSON.stringify(where));
  return http.get(path)
}

http.upsert = (model, data) => {
  let path = '{0}'.format(model);
  return http.post(path, data)
}

http.update = (model, where, data) => {
  let path = '{0}/update?where={1}'.format(model, JSON.stringify(where));
  return http.post(path, data)
}

http.upsertWithWhere = (model, where, data) => {
  let path = '{0}/upsertWithWhere?where={1}'.format(model, JSON.stringify(where));
  return http.post(path, data)
}

http.deleteById = (model, guid) => {
  let path = '{0}/{1}'.format(model, guid);
  return http.delete(path)
}

http.resetMail = (name, email) => {
  var session = axios.create({
    baseURL: dns ? `${proto}://${restApiHost}` : `${proto}://${restApiHost}:${port}`,
  });
  let path = '/reset?domain={0}&username={1}&email={2}'.format(restApiHost, name, email);
  return session.get(path);
}

http.method = (model, method, data) => {
  let path = '{0}/{1}'.format(model, method);
  return http.post(path, data)
}

/* Response Interceptors */
const interceptResErrors = (err) => {
  // console.log('error', err);
  let status = err.response.status;
  try {
    setLoading(
      false,
      err.config.uid || err.response.config.uid
    );
    err = Object.assign(new Error(), err.response.data.error);
  } catch (e) {
    // Will return err if something goes wrong
  }
  if (status == 401) {
    setTimeout(function() {
      router.push('/pages/login');  
    }, 100);
  }
  return Promise.reject(err);
};
const interceptResponse = (res) => {
  // console.log('response', res.config);
  setLoading(false, res.config.uid);
  try {
    return res.data;
  } catch (e) {
    return res;
  }
};
http.interceptors.response.use(interceptResponse, interceptResErrors);

// Set storage Token in http if exists
addTokenFromLocalStorage(http);

/* Request Interceptors */
const interceptReqErrors = err => Promise.reject(err);
const interceptRequest = (config) => {
  config.uid = setLoading(true, config.uid);
  return config;
};
http.interceptors.request.use(interceptRequest, interceptReqErrors);

var subscriber = undefined;
function generateUUID() {
  var d = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
}

http.Subscribe = (guid, callback, prefix='tech9', login='tech9', password='1111') => {
  var url = '{0}://{1}:{2}/ws'.format(info.proto, info.host, info.port)
  var login = login
  var password = password
  if (subscriber && subscriber.connected) {
    subscriber = undefined;
  }

  var queueToken = generateUUID();
  subscriber = Stomp.client(url)
  var debug = function(str) {
    // console.log(' DEBUG: ', str)
  };
  subscriber.debug = debug
  // the client is notified when it is connected to the server.
  var onconnect = function(frame) {
    console.log("connected to Stomp");
    try {
      subscriber.subscribe('/exchange/{0}_{1}'.format(prefix, guid),
        function(frame) {
          callback(frame)
        }, {
          'durable': true,
          'auto-delete': true,
          'x-queue-name': '{0}_{1}'.format(prefix, queueToken)
        });
    } catch (err) {
      console.log("stomp error:", err)
    }
  };
  subscriber.onerror = function(frame) {
    console.error("on error from Stomp : ", frame);
    setTimeout(function() {
      console.error("reconnecting to Stomp...");
      subscriber.connect(login, passcode, onconnect);
    }, 5000)
  };
  subscriber.ondisconnect = function() {
    console.info("disconnected from Stomp");
    setTimeout(function() {
      console.error("reconnecting to Stomp...");
      subscriber.connect(login, passcode, onconnect);
    }, 5000)
  };
  subscriber.connect(login, password, onconnect);

}

export default http;

// Documentation: https://github.com/axios/axios
