import loopback from '@/services/loopback';
import router from '@/router';

/*
 * @param filter: 
 * {
 *   where: {
 *     something: 'value'
 *   }
 * }
 * @param where: 
 * {
 *   something: 'value'
 * }
*/
export function find({commit, dispatch, state}, {model, filter}) {
  return loopback
    .find(model, filter)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function count({commit, dispatch, state}, {model, where}) {
  return loopback
    .count(model, where)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function update({commit, dispatch, state}, {model, where, data}) {
  return loopback
    .update(model, where, data)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function upsert({commit, dispatch, state}, {model, data}) {
  return loopback
    .upsert(model, data)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function findById({commit, dispatch, state}, {model, guid}) {
  return loopback
    .findById(model, guid)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function upsertWithWhere({commit, dispatch, state}, {model, where, data}) {
  return loopback
    .upsertWithWhere(model, where, data)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function deleteById({commit, dispatch, state}, {model, guid}) {
  return loopback
    .deleteById(model, guid)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

/*
 * @param - ex
 * model : 'devices'
 * method : 'Registration;
 * data : {
 *   device_guid: "xxx",
 *   imei: "xxx",
 *   net_type: "xxx"
 * }
 *
 */

export function method({commit, dispatch, state}, {model, method, data}) {
  return loopback
    .method(model, method, data)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}