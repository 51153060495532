import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/dashboard/Dashboard')
const CheckService = () => import('@/views/dashboard/CheckService')
const Summarize = () => import('@/views/dashboard/Summarize')
const DeviceManage = () => import('@/views/device/DeviceManage')
const UserManage = () => import('@/views/user/UserManage')
const SiteManage = () => import('@/views/site/SiteManage')
const EventManage = () => import('@/views/event/EventManage')
const DeviceMonitor = () => import('@/views/manage/DeviceMonitor')
const LoginHistory = () => import('@/views/manage/LoginHistory')

// btfs
const BTFSBoard = () => import('@/views/btfs/Dashboard')
const BTFSDeviceManage = () => import('@/views/btfs/DeviceManage')

// codeg
const CODEGBoard = () => import('@/views/codeg/Dashboard')
const CODEGDeviceManage = () => import('@/views/codeg/DeviceManage')

// thcs
const THCSBoard = () => import('@/views/thcs/Dashboard')
const THCSDeviceManage = () => import('@/views/thcs/DeviceManage')

// watek
const WATEKBoard = () => import('@/views/watek/Dashboard')
const WATEKDeviceManage = () => import('@/views/watek/DeviceManage')

// srds
const SRDSBoard = () => import('@/views/srds/Dashboard')
const SRDSDeviceManage = () => import('@/views/srds/device/DeviceManage')
const SRDSSiteManage = () => import('@/views/srds/site/SiteManage')
const SRDSEventManage = () => import('@/views/srds/event/EventManage')

//military 
const MilitaryBoard = () => import('@/views/military/Dashboard')

// Pinewilt Disease Monitoring 
const PWDMBoard = () => import('@/views/pwdm/Dashboard')

// fumax
const FumaxBoard = () => import('@/views/fumax/Dashboard')
const FumaxDeviceManage = () => import('@/views/device/DeviceManage')
const FumaxSnowBoard = () => import('@/views/fumax_snow/Dashboard')
const FumaxSnowDeviceManage = () => import('@/views/device/DeviceManage')

// door
const DoorBoard = () => import('@/views/door/Dashboard')
const DoorDeviceManage = () => import('@/views/device/DeviceManage')

// swfm
const SWFMBoard = () => import('@/views/swfm/Dashboard')
const SWFMDeviceManage = () => import('@/views/swfm/DeviceManage')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

const Maps = () => import('@/views/maps/GoogleMap')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard_',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard_',
          name: 'Dashboard_',
          component: CheckService,
          meta: {requiresAuth: true}
        },
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {requiresAuth: true}
        },
        {
          path: 'device',
          meta: {
            label: 'Device'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: ':id',
              meta: {
                label: 'Device Details'
              },
              name: 'Device',
              component: DeviceManage
            }
          ]
        },
        {
          path: 'summarize',
          name: 'Summarize',
          meta: {
            label: 'Summarize',
            requiresAuth: true
          },
          component: Summarize
        },
        {
          path: 'users',
          name: 'Users',
          component: UserManage,
          meta: {
            label: 'Users',
            requiresAuth: true
          }
        },
        {
          path: 'sites',
          meta: {
            label: 'Sites',
            requiresAuth: true
          },
          component: SiteManage,
          children: [
            {
              path: ':id',
              meta: {
                label: 'Sites',
                requiresAuth: true
              },
              name: 'Sites',
              component: SiteManage
            }
          ]
        },
        {
          path: 'events',
          name: 'Events',
          component: EventManage,
          meta: {
            label: 'Events',
            requiresAuth: true
          }
        },
        {
          path: 'manage',
          name: 'Manage',
          component: DeviceMonitor,
          meta: {
            label: 'Manage',
            requiresAuth: true
          }
        },        
        {
          path: 'loginhistory',
          name: 'Logs',
          component: LoginHistory,
          meta: {
            label: 'Logs',
            requiresAuth: true
          }
        },
        {
          path: 'btfs',
          name: 'BTFSBoard',
          component: BTFSBoard,
          meta: {requiresAuth: true}
        },
        {
          path: 'btfs_device',
          meta: {
            label: 'Device'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: ':id',
              meta: {
                label: 'Device Details'
              },
              name: 'BTFSDevice',
              component: BTFSDeviceManage
            }
          ]
        },
        //military
        {
          path: 'military',
          name: 'MilitaryBoard',
          component: MilitaryBoard,
          meta: {requiresAuth: true}

        }, 
        //pwdm
        {
          path: 'pwdm',
          name: 'PWDMBoard',
          component: PWDMBoard,
          meta: {requiresAuth: true}

        }, 
        //codeg
        {
          path: 'codeg',
          name: 'CODEGBoard',
          component: CODEGBoard,
          meta: {requiresAuth: true}
        },
        {
          path: 'codeg_device',
          meta: {
            label: 'Device'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: ':id',
              meta: {
                label: 'Device Details'
              },
              name: 'CODEGDevice',
              component: CODEGDeviceManage
            }
          ]
        },

        {
          path: 'thcs',
          name: 'THCSBoard',
          component: THCSBoard,
          meta: {requiresAuth: true}
        },
        {
          path: 'thcs_device',
          meta: {
            label: 'Device'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: ':id',
              meta: {
                label: 'Device Details'
              },
              name: 'THCSDevice',
              component: THCSDeviceManage
            }
          ]
        },        
        {
          path: 'watek',
          name: 'WATEKBoard',
          component: WATEKBoard,
          meta: {requiresAuth: true}
        },
        {
          path: 'watek_device',
          meta: {
            label: 'Device'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: ':id',
              meta: {
                label: 'Device Details'
              },
              name: 'WATEKDevice',
              component: WATEKDeviceManage
            }
          ]
        },    
        {
          path: 'swfm',
          name: 'SWFMBoard',
          component: SWFMBoard,
          meta: {requiresAuth: true}
        },
        {
          path: 'swfm_device',
          meta: {
            label: 'SWFMDevice'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: ':id',
              meta: {
                label: 'Device Details'
              },
              name: 'SWFMDevice',
              component: SWFMDeviceManage
            }
          ]
        },             
        {
          path: 'srds',
          name: 'SRDSBoard',
          component: SRDSBoard,
          meta: {requiresAuth: true}
        },
        {
          path: 'srds_device',
          meta: {
            label: 'Device'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: ':id',
              meta: {
                label: 'Device Details'
              },
              name: 'SRDSDevice',
              component: SRDSDeviceManage
            }
          ]
        },
        {
        path: 'srds_summarize',
          name: 'SRDSSummarize',
          meta: {
            label: 'SRDSSummarize',
            requiresAuth: true
          },
          component: Summarize
        },
        {
          path: 'srds_sites',
          meta: {
            label: 'SRDSSites',
            requiresAuth: true
          },
          component: SRDSSiteManage,
          props: {
            title: "설치 장소 목록",
            prefix: "srds_"
          },
          children: [
            {
              path: ':id',
              meta: {
                label: 'SRDSSites',
                requiresAuth: true
              },
              name: 'SRDSSites',
              component: SRDSSiteManage,
              props: {
                title: "설치 장소 목록",
                prefix: "srds_"
              }
            }
          ]
        },
        {
          path: 'srds_users',
          name: 'SRDSUsers',
          component: UserManage,
          props: {
            prefix: "srds_"
          },
          meta: {
            label: 'SRDSUsers',
            requiresAuth: true
          }
        },        
        {
          path: 'srds_events',
          name: 'SRDSEvents',
          component: SRDSEventManage,
          props: {
            prefix: "srds_"
          },
          meta: {
            label: 'SRDSEvents',
            requiresAuth: true
          }
        },
        {
          path: 'fumax',
          name: 'FumaxBoard',
          component: FumaxBoard,
          meta: {requiresAuth: true}
        },
        {
          path: 'fumax_device',
          meta: {
            label: 'Device'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: ':id',
              meta: {
                label: 'Device Details'
              },
              name: 'FumaxDevice',
              component: FumaxDeviceManage
            }
          ]
        },
        {
          path: 'fumax_snow',
          name: 'FumaxSnowBoard',
          component: FumaxSnowBoard,
          meta: {requiresAuth: true}
        },
        {
          path: 'fumax_snow_device',
          meta: {
            label: 'Device'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: ':id',
              meta: {
                label: 'Device Details'
              },
              name: 'FumaxSnowDevice',
              component: FumaxSnowDeviceManage
            }
          ]
        },
        {
          path: 'door',
          name: 'DoorBoard',
          component: DoorBoard,
          meta: {requiresAuth: true}
        },
        {
          path: 'door_device',
          meta: {
            label: 'Device'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: ':id',
              meta: {
                label: 'Device Details'
              },
              name: 'DoorDevice',
              component: DoorDeviceManage
            }
          ]
        },        
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ]
    }
  ]
}

