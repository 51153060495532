<template>
  <KModal
    :show.sync="showModal"
    :no-close-on-backdrop="true"
    :centered="true"
    title="Notify Dialog"
    :color="color"
    size="sm"
  >
    <div>
    {{message}}
    </div>
    <template #header>
      <h6 class="modal-title text-center">{{title}}</h6>
    </template>
    <template #footer-wrapper>
      <!-- empty footer -->
      <div></div>
    </template>
  </KModal>
</template>

<script>
export default {
  name: 'Notify',
  props: {
    color: {
      type: String,
      default: 'warning'
    }
  },
  data() {
    return {
      showModal: false,
      title: '알림',
      which: 'notify',
      message: 'this is placeholder of message',
    }
  },
  methods: {
    hide() {
      this.showModal = false;
    },
    show(message, timeout=5000) {
      var self = this;
      this.message = message;
      this.showModal = true;

      setTimeout(function() {
        self.hide()
      }, timeout)
    }
  }
}
</script>